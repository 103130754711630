import "vendor/w3/accordion"
;(function($) {
  /**
   * Mobile Slider
   */
  const initMobileSlider = function() {
    const $mobileSlide = $(".slider-mobile")

    // Only init if window is small enough
    if (window.innerWidth < 750) {
      // Check if NOT already a slick slider
      if (!$mobileSlide.hasClass("slick-slider")) {
        $mobileSlide.slick({
          infinite: true,
          slidesToShow: 1,
          autoplay: false,
          centerMode: true,
          centerPadding: "20px"
        })
      }
    } else {
      // Check if slick slider has been created
      if ($mobileSlide.hasClass("slick-slider")) {
        $mobileSlide.slick("unslick") // Destroy slider
      }
    }
  }

  /**
   * Tablet Slider
   */
  const initTabletSlider = function() {
    const $tabletSlide = $(".slider-tablet")

    // Only init if window is small enough
    if (window.innerWidth < 1000) {
      // Check if NOT already a slick slider
      if (!$tabletSlide.hasClass("slick-slider")) {
        $tabletSlide.slick({
          infinite: true,
          slidesToShow: 2,
          autoplay: false,
          responsive: [
            {
              breakpoint: 750,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "20px"
              }
            }
          ]
        })
      }
    } else {
      // Check if slick slider has been created
      if ($tabletSlide.hasClass("slick-slider")) {
        $tabletSlide.slick("unslick") // Destroy slider
      }
    }
  }

  /**
   * Multi Slider
   */
  const initMultiSlider = function() {
    const $SliderMulti = $(".slider-multi")
    $SliderMulti.slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      centerMode: true,
      centerPadding: "20px",
      responsive: [
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }

  /**
   * Single Slider
   */
  const initSingleSlider = function() {
    const $SliderSingle = $(".slider-single")
    $SliderSingle.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false
    })
  }

  /**
   * Mobile menu dropdown toggle
   */
  const mobileMenuToggle = function() {
    if (window.innerWidth < 900) {
      $(".menu-item-has-children > .sub-menu-toggle").on("click", function(e) {
        e.preventDefault()
        $(this)
          .parent()
          .toggleClass("toggled")
      })
    }
  }

  /**
   * Popup cards toggle function
   */
  const togglePopupCard = function(element) {
    const $this = $(element)
    const popupShow = "popup-show"

    // Check if in slider mode
    if ($this.parents(".slick-slider").length > 0) {
      // Get slick slider
      const slickSlider = $this.parents(".slick-slider")

      // Get slick track and transform
      const slickTrack = $this.parent(".slick-track")
      const slickTrackTransform = slickTrack.css("transform")

      // Check for transform - slider track transform is set
      if (slickTrackTransform !== "none") {
        // Cache the slider track transform
        window.pxlSlickTrackTransformCache = slickTrackTransform
      }

      // About to close popup
      if ($this.hasClass(popupShow)) {
        // Set transform back to the original slide position
        slickTrack.css("transform", window.pxlSlickTrackTransformCache)
        // Allow slider to be dragged
        slickSlider.slick("slickSetOption", {
          draggable: true,
          touchMove: true,
          swipe: true
        })

        // About to open popup
      } else {
        // Remove transform
        slickTrack.css("transform", "none")
        // Disallow slider being dragged
        slickSlider.slick("slickSetOption", {
          draggable: false,
          touchMove: false,
          swipe: false
        })
      }
    }

    // Set or remove html no-scroll class
    if ($("html").hasClass("no-scroll")) {
      noScroll(false)
    } else {
      noScroll(true)
    }

    // Toggle popup class
    $this.closest(".popup-card").toggleClass(popupShow)
  }

  /**
   * Sets whether the user can scroll or not
   * @param  {Boolean} [noscroll=false] Whether to add or remove the no-scroll classname
   */
  const noScroll = (noscroll = false) => {
    const $html = $("html")

    if (noscroll) {
      $html.addClass("no-scroll")
    } else {
      $html.removeClass("no-scroll")
    }
  }

  /**
   * Document Ready - Let's run some js!
   */
  $(document).ready(function() {
    initMobileSlider()
    initTabletSlider()
    initMultiSlider()
    initSingleSlider()
    mobileMenuToggle()

    // Toggle popup card on click or keypress
    $(".popup-trigger").on("click", function(e) {
      e.preventDefault()

      // Toggle popup card
      togglePopupCard(this)
      return false
    })

    $(".popup-trigger:not(button)").on("keypress", function(e) {
      e.preventDefault()

      // Space or Enter
      if (e.which == 13 || e.which == 32) {
        togglePopupCard(this)
      }
      return false
    })

    $(document).on("keydown", function(e) {
      // Close popup
      if ($(".popup-card").hasClass("popup-show")) {
        // Escape
        if (e.keyCode == 27) {
          togglePopupCard($(".popup-card.popup-show .popup"))
        }
      }
    })
  })

  /**
   * Window Resize
   */
  $(window).resize(function() {
    initMobileSlider()
    initTabletSlider()
    mobileMenuToggle()
  })
})(jQuery)
